<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>学员添加/编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label="基地" prop="F_IN_JID_ID" :rules="[{required:false,message:'基地不能为空',trigger: 'blur' }]">
                        <el-select v-model="info.F_IN_JID_ID" placeholder="请选择基地" @change="selectChange()">
                            <el-option label="请选择基地" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['jid']" :label="v.F_VR_JIDMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="营期" prop="F_IN_YINGQ_ID" :rules="[{required:false,message:'营期不能为空',trigger: 'blur' }]">
                        <el-select v-model="info.F_IN_YINGQ_ID" placeholder="请选择营期" @change="selectChange()">
                            <el-option label="请选择营期" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingq']" :label="v.F_IN_NAME" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="营期期限" prop="F_IN_YINGQQX_ID" :rules="[{required:false,message:'营期期限不能为空',trigger: 'blur' }]">
                        <el-select v-model="info.F_IN_YINGQQX_ID" placeholder="请选择营期期限" @change="selectChange()">
                            <el-option label="请选择营期期限" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingqx']" :label="v.F_VR_QISHU" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="课程" prop="F_IN_KEC_ID" :rules="[{required:false,message:'课程不能为空',trigger: 'blur' }]">
                        <el-select v-model="info.F_IN_KEC_ID" placeholder="请选择课程" @change="selectChange()">
                            <el-option label="请选择营期期限" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['kec']" :label="v.F_VR_JIANJ" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="班级" prop="F_IN_YINGDBJ">
                        <el-select v-model="info.F_IN_YINGDBJ" placeholder="请选择班级" @change="selectChange()">
                            <el-option label="请选择班级" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['banj']" :label="v.F_VR_BANJMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="招生老师" prop="F_IN_ZSLS_ID" :rules="[{required:false,message:'招生老师不能为空',trigger: 'blur' }]">
                        <el-select v-model="info.F_IN_ZSLS_ID" placeholder="请选择招生老师" @change="selectChange()">
                            <el-option label="请选择招生老师" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['zsls']" :label="v.F_VR_XINGM" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="学员姓名" prop="F_VR_XINGM" :rules="[{required:false,message:'姓名不能为空',trigger: 'blur' }]"><el-input placeholder="请输入姓名" v-model="info.F_VR_XINGM"></el-input></el-form-item>
                    <el-form-item label="头像" prop="thumb"><el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                        <img v-if="info.F_VR_TOUX" :src="info.F_VR_TOUX" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>
                    <el-form-item label="性别" prop="F_TI_XINGB">
                        <el-select v-model="info.F_TI_XINGB" placeholder="选择性别">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="出生日期">
                        <el-date-picker
                                v-model="info.F_VR_CHUSRQ"
                                type="date"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="年龄" prop="F_IN_NIANL" :rules="[{required:false,message:'年龄不能为空',trigger: 'blur' }]"><el-input placeholder="请输入年龄" v-model="info.F_IN_NIANL"></el-input></el-form-item>
                    <el-form-item label="民族" prop="F_VR_MINGZ" :rules="[{required:false,message:'民族不能为空',trigger: 'blur' }]"><el-input placeholder="请输入民族" v-model="info.F_VR_MINGZ"></el-input></el-form-item>
                    <el-form-item label="籍贯" prop="F_VR_JIG" :rules="[{required:false,message:'籍贯不能为空',trigger: 'blur' }]"><el-input placeholder="请输入籍贯" v-model="info.F_VR_JIG"></el-input></el-form-item>
                    <el-form-item label="身份证" prop="F_VR_SHENFZ" :rules="[{required:false,message:'身份证不能为空',trigger: 'blur' }]"><el-input placeholder="请输入身份证" v-model="info.F_VR_SHENFZ"></el-input></el-form-item>
                    <el-form-item label="身高" prop="F_IN_SHENG" :rules="[{required:false,message:'身高不能为空',trigger: 'blur' }]"><el-input placeholder="请输入身高" v-model="info.F_IN_SHENG"></el-input></el-form-item>
                    <el-form-item label="体重" prop="F_VR_TIZ" :rules="[{required:false,message:'体重不能为空',trigger: 'blur' }]"><el-input placeholder="请输入体重" v-model="info.F_VR_TIZ"></el-input></el-form-item>
                    <el-form-item label="兴趣爱好" prop="F_VR_XINGQAH" :rules="[{required:false,message:'兴趣爱好不能为空',trigger: 'blur' }]"><el-input placeholder="请输入兴趣爱好" v-model="info.F_VR_XINGQAH"></el-input></el-form-item>
                    <el-form-item label="个人专长" prop="F_VR_GERZC" :rules="[{required:false,message:'个人专长不能为空',trigger: 'blur' }]"><el-input placeholder="请输入个人专长" v-model="info.F_VR_GERZC"></el-input></el-form-item>
                    <el-form-item label="学校名称" prop="F_VR_XUEXMC" :rules="[{required:false,message:'学校名称不能为空',trigger: 'blur' }]"><el-input placeholder="请输入学校名称" v-model="info.F_VR_XUEXMC"></el-input></el-form-item>
                    <el-form-item label="班级" prop="F_VR_BANJ" :rules="[{required:false,message:'班级不能为空',trigger: 'blur' }]"><el-input placeholder="请输入班级" v-model="info.F_VR_BANJ"></el-input></el-form-item>
                    <el-form-item label="家长寄语" prop="F_TE_JIAZJY" :rules="[{required:false,message:'家长寄语不能为空',trigger: 'blur' }]"><el-input placeholder="请输入家长寄语" v-model="info.F_TE_JIAZJY"></el-input></el-form-item>
                    <el-form-item label="监护人姓名" prop="F_VR_JIANHRXM" :rules="[{required:false,message:'监护人姓名不能为空',trigger: 'blur' }]"><el-input placeholder="请输入监护人姓名" v-model="info.F_VR_JIANHRXM"></el-input></el-form-item>


                    <el-form-item label="工作单位" prop="F_VR_GONGZDW" :rules="[{required:false,message:'工作单位不能为空',trigger: 'blur' }]"><el-input placeholder="请输入工作单位" v-model="info.F_VR_GONGZDW"></el-input></el-form-item>
                    <el-form-item label="联系方式" prop="F_VR_LIANXFS" :rules="[{required:false,message:'联系方式不能为空',trigger: 'blur' }]"><el-input placeholder="请输入家长寄语" v-model="info.F_VR_LIANXFS"></el-input></el-form-item>
                    <el-form-item label="与营员关系" prop="F_VR_GUANX" :rules="[{required:false,message:'与营员关系不能为空',trigger: 'blur' }]"><el-input placeholder="请输入与营员关系" v-model="info.F_VR_GUANX"></el-input></el-form-item>
                    <el-form-item label="紧急联系电话" prop="F_VR_JINJLXDH" :rules="[{required:false,message:'紧急联系电话不能为空',trigger: 'blur' }]"><el-input placeholder="请输入紧急联系电话" v-model="info.F_VR_JINJLXDH"></el-input></el-form-item>
                    <el-form-item label="详细住址" prop="F_VR_XIANGXZZ" :rules="[{required:false,message:'详细住址不能为空',trigger: 'blur' }]"><el-input placeholder="请输入详细住址" v-model="info.F_VR_XIANGXZZ"></el-input></el-form-item>

                    <el-form-item label="获取招生信息地方" prop="F_VR_HUAOQ" :rules="[{required:false,message:'获取招生信息地方不能为空',trigger: 'blur' }]"><el-input placeholder="请输入获取招生信息地方" v-model="info.F_VR_HUAOQ"></el-input></el-form-item>

                    <el-form-item label="是否已交定金" prop="F_TI_DINGJZT">
                        <el-select v-model="info.F_TI_DINGJZT" placeholder="选择状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="已交定金" value="1"></el-option>
                            <el-option label="未交定金" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="定金金额" prop="F_VR_DINGJ" :rules="[{required:false,message:'定金金额不能为空',trigger: 'blur' }]"><el-input placeholder="请输入定金金额" v-model="info.F_VR_DINGJ"></el-input></el-form-item>


                    <el-form-item label="是否已交尾款" prop="F_TI_WEIKZT">
                        <el-select v-model="info.F_TI_WEIKZT" placeholder="选择状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="已交尾款" value="1"></el-option>
                            <el-option label="未交尾款" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="尾款金额" prop="F_VR_WEIK" :rules="[{required:false,message:'尾款金额不能为空',trigger: 'blur' }]"><el-input placeholder="请输入尾款金额" v-model="info.F_VR_WEIK"></el-input></el-form-item>



                    <el-form-item label="是否退营" prop="F_TI_SHIFTY">
                        <el-select v-model="info.F_TI_SHIFTY" placeholder="选择状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未退营" value="1"></el-option>
                            <el-option label="已退营" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注" prop="F_TE_BEIZ" :rules="[{required:false,message:'备注不能为空',trigger: 'blur' }]"><el-input placeholder="请输入备注" v-model="info.F_TE_BEIZ"></el-input></el-form-item>



                    <el-form-item label="广告计划" prop="F_VR_GUANGGJH" :rules="[{required:false,message:'广告计划不能为空',trigger: 'blur' }]"><el-input placeholder="请输入广告计划" v-model="info.F_VR_GUANGGJH"></el-input></el-form-item>
                    <el-form-item label="流量来源" prop="F_VR_LIULLY" :rules="[{required:false,message:'流量来源不能为空',trigger: 'blur' }]"><el-input placeholder="请输入流量来源" v-model="info.F_VR_LIULLY"></el-input></el-form-item>
                    <el-form-item label="广告创建时间" prop="F_VR_GUANGGCJSJ" :rules="[{required:false,message:'广告创建时间不能为空',trigger: 'blur' }]"><el-input placeholder="请输入广告创建时间" v-model="info.F_VR_GUANGGCJSJ"></el-input></el-form-item>


                    <!--<el-form-item label="入营通知书" prop="thumb"><el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccesses" >
                        <img v-if="info.F_VR_TONGZS" :src="info.F_VR_TONGZS" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>

                    <el-form-item label="收据" prop="thumb"><el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccessess" >
                        <img v-if="info.F_VR_SHOUJ" :src="info.F_VR_SHOUJ" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>-->

                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <!-- <el-button @click="resetForm('info')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          edit_id:0,
          info:{
              adv_sort:0,
              adv_type:0,
              ap_id:0,
          },
          list:[],
          upload_headers:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        resetForm:function(e){
            this.$refs[e].resetFields();
        },
        submitForm:function(e){
            let _this = this;

            // 验证表单
            this.$refs[e].validate(function(res){
                if(res){
                    //  判断是Add 或者 Edit
                    let url = _this.$api.addXueyxj;
                    if(_this.edit_id>0){
                        url = _this.$api.editXueyxj+_this.edit_id;
                    }
                    // Http 请求
                    _this.$post(url,_this.info).then(function(res){
                        if(res.code == 200){
                            _this.$message.success("编辑成功");
                            _this.$router.go(-1);
                        }else{
                            _this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_adv_info:function(){
            let _this = this;
            this.$get(this.$api.editXueyxj+this.edit_id).then(function(res){
                _this.info = res.data.xuanyxj;
                _this.list = res.data;
            })

        },
        get_adv_list:function(){
            let _this = this;
            this.$get(this.$api.addXueyxj).then(function(res){
                _this.list = res.data;
            })

        },
        selectChange:function(){
            this.$forceUpdate();
        },
        handleAvatarSuccess(res) {
            this.info.F_VR_TOUX = res.data;
            this.$forceUpdate();
        },
        handleAvatarSuccesses(res) {
            this.info.F_VR_TONGZS = res.data;
            this.$forceUpdate();
        },
        handleAvatarSuccessess(res) {
            this.info.F_VR_SHOUJ = res.data;
            this.$forceUpdate();
        },
    },
    created() {
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到

        if(!this.$isEmpty(this.$route.params.adv_position_id)){
            this.info.ap_id = this.$route.params.adv_position_id;
        }

        // 判断是否是编辑
        if(!this.$isEmpty(this.$route.params.id)){
            this.edit_id = this.$route.params.id;
        }

        if(this.edit_id>0){
            this.get_adv_info();
        }else{
            this.get_adv_list();
        }
        
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.line{text-align: center}
</style>